import * as React from "react";
import Layout from "../components/layout";

const ProjectsPage = () => {
  return (
    <Layout pageTitle="Spring Onion Projects">
      <h1 className="text-center text-2xl">Projects</h1>
      <div className="grid grid-cols-1 justify-items-center">
        <p className="mt-2 mb-4 text-center">
          Spring Onion's projects will be integrated into the <a href="https://goliathonline.net/">Goliath Online</a> portfolio of websites.
        </p>
      </div>
    </Layout>
  );
};

export default ProjectsPage;
